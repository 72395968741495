<template>
  <div>
    <CRow class="mb-2">
      <CCol>
        <h2>Pending Zoho Customer Accounts</h2>
        <span>The following customer accounts are pending creation. Audit entries before creating the account to ensure proper invoicing.</span>
      </CCol>
    </CRow>
    <TableNotifications
      :errors="errors"
      :warnings="warnings"
      :successes="successes"
      :texts="{ error: 'Error for customer: ', warning: 'Warning for customer: ', success: 'Success for customer: ' }"
    />
    <RSpinnerOverlay
      :loading="loadingBatch"
      :opacity="0.7"
    >
      <TableActions
        :total="total"
        :selected="selected.length"
        :page-size.sync="pageSize"
        :actions="[
          { name: 'batch-create', text: 'Create Customer Accounts' },
          { name: 'batch-remove', text: 'Remove CAD Customers' }
        ]"
        @refresh="fetchProperties"
        @batch-create="handleBatchCreate"
        @batch-remove="handleBatchRemove"
      />
      <TableData
        :data="tableData"
        :loading="loading"
        @selection-change="selected = $event"
        @edit-property="editProperty($event)"
      />
    </RSpinnerOverlay>
    <PendingCustomersAside
      :show.sync="showAside"
      :property="editTarget.property"
      :company="editTarget.company"
      @update:property="handleUpdate"
      @create-account="handleCreate"
    />
  </div>
</template>

<script>
import { RSpinnerOverlay } from '@/components/spinners'
import { RooofAPI } from '@/services/api/resources'
import { createZohoAccount } from '@/views/staff/reporting/zoho/_components/utils'

import PendingCustomersAside from './_components/PendingCustomersAside'
import TableActions from './_components/TableActions'
import TableData from './_components/TableData'
import TableNotifications from './_components/TableNotifications'

export default {
  name: 'PendingZohoCustomer',
  components: {
    TableActions,
    TableData,
    TableNotifications,
    PendingCustomersAside,
    RSpinnerOverlay
  },
  data () {
    return {
      loading: false,
      loadingBatch: false,
      total: 0,
      pageSize: 100,
      tableData: [],
      selected: [],
      errors: [],
      warnings: [],
      successes: [],
      showAside: false,
      editTarget: {
        company: null,
        property: null
      }
    }
  },
  created () {
    this.fetchProperties()
  },
  methods: {
    /**
     * Fetch the list of properties which are pending
     * zoho customer account creation.
     */
    async fetchProperties () {
      this.errors = []
      this.warnings = []
      this.loading = true

      const params = new URLSearchParams()
      params.append('page_size', this.pageSize)
      params.append('master_invoice', false)
      params.append('zoho_customer_id', 'null')
      params.append('status', 'active')
      params.append('status', 'onboarding')
      params.append('ordering', '-start_date')
      params.append('exclude_test_companies', true)
      params.append('hide_from_pending_customer_report', false)

      const response = await RooofAPI.properties.list({ params })
      if (response) {
        this.total = response.data.count
        this.tableData = this.formatTableData(response.data.results)
      }
      this.loading = false
    },
    /**
     * Send a request to create the zoho customer account.
     *
     * @param {Object} row
     * @returns {Promise}
     */
    createZohoAccount (row) {
      const { property, company } = row
      return createZohoAccount(property, company)
    },
    /**
     * Format the property data returned from the api
     * so it can be used in the table. Adds `company`
     * data.
     *
     * @param {Array} data - property data
     */
    formatTableData (data) {
      return data.map(property => {
        const company = this.$store.getters['rooof/getCompanyById'](property.company)
        return {
          company,
          property
        }
      })
    },
    /**
     * Display the property form aside.
     *
     * @param {Object} row
     */
    editProperty (row) {
      this.editTarget = row
      this.showAside = true
    },
    /**
     * Update the table row object post-save.
     *
     * @param {Object} data - new property data
     */
    handleUpdate (data) {
      const index = this.tableData.findIndex(row => row.property.id === data.id)
      const row = this.tableData[index]

      this.$set(row, 'property', data)
      this.$set(this.tableData, index, row)

      this.$store.commit('notification/show', {
        message: `${row.property.name} saved`,
        type: 'success'
      })
    },
    /**
     * Create a single zoho customer and update the
     * table data.
     *
     * @param {Number} propertyId
     */
    async handleCreate (propertyId) {
      const index = this.tableData.findIndex(row => row.property.id === propertyId)
      const row = this.tableData[index]

      try {
        const response = await this.createZohoAccount(row)
        if (response.data.is_duplicate_contact) {
          this.warnings.push({
            row, message: 'Duplicate contact'
          })
        }
        this.tableData.splice(index, 1)
        this.successes.push({ row })
      } catch (err) {
        this.errors.push({
          row, message: err.response
        })
      }
    },
    /**
     * Remove a single canadian customer and update the
     * table data.
     *
     * @param {Number} propertyId
     */
    async handleRemove (propertyId) {
      const index = this.tableData.findIndex(row => row.property.id === propertyId)
      const row = this.tableData[index]

      try {
        if (row.company.currency_code === 'CAD') {
          row.property.hide_from_pending_customer_report = true
          await RooofAPI.properties.partialUpdate({ id: row.property.id, data: row.property }, false)
          this.tableData.splice(index, 1)
          this.successes.push({ row })
        } else {
          this.warnings.push({
            row, message: 'Can only remove a Canadian customer'
          })
        }
      } catch (err) {
        this.errors.push({
          row, message: err.response
        })
      }
    },
    /**
     * Send a request to create a zoho account for every
     * selected row in the table.
     */
    async handleBatchCreate () {
      this.loadingBatch = true
      const promises = []
      for (const row of this.selected) {
        promises.push(this.handleCreate(row.property.id))
      }
      await Promise.all(promises)
      this.loadingBatch = false
    },
    /**
     * Remove all selected Canadian customers from the table.
     */
    async handleBatchRemove () {
      this.loadingBatch = true
      const promises = []
      for (const row of this.selected) {
        promises.push(this.handleRemove(row.property.id))
      }
      await Promise.all(promises)
      this.loadingBatch = false
    }
  }
}
</script>
