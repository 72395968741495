<template>
  <CRow>
    <CCol>
      <RDataTable
        select
        :items="data"
        :fields="columns"
        :table-props="{ loading, sorter: false }"
        @selection-change="$emit('selection-change', $event)"
      >
        <template #customer_account="{ item }">
          <td>
            <div class="pb-1">
              {{ item.company ? item.company.human_name : 'Unknown' }}
            </div>
            <div class="pb-1">
              <CLink :href="getPropertyLink(item)" target="_blank">
                {{ item.property.name }}
              </CLink>
            </div>
            <div v-if="item.property.legal_name" class="faded">
              Legal: {{ item.property.legal_name }}
            </div>
          </td>
        </template>
        <template #property_address="{ item }">
          <td>
            <div
              v-if="item.property.property_address && item.property.property_address.attention_to"
              class="faded"
            >
              Attn: {{ item.property.property_address.attention_to }}
            </div>
            <div>
              {{ formatAddress(item.property.property_address) }}
            </div>
          </td>
        </template>
        <template #billling_address="{ item }">
          <td>
            <div
              v-if="item.property.billing_address && item.property.billing_address.attention_to"
              class="faded"
            >
              Attn: {{ item.property.billing_address.attention_to }}
            </div>
            <div>
              {{ formatAddress(item.property.billing_address) }}
            </div>
          </td>
        </template>
        <template #billing_email="{ item }">
          <td>
            {{ item.property.billing_email }}
          </td>
        </template>
        <template #currency_code="{ item }">
          <td>
            {{ item.company.currency_code }}
          </td>
        </template>
        <template #edit="{ item }">
          <td>
            <CIcon
              name="md-edit"
              class="icon-button"
              @click.native="$emit('edit-property', item)"
            />
          </td>
        </template>
      </RDataTable>
    </CCol>
  </CRow>
</template>

<script>
import { RDataTable } from '@/components/tables'
import { formatAddress } from '@/utils'

const columns = [
  { key: 'customer_account', label: 'Customer Account', _style: 'width: 20%' },
  { key: 'property_address', label: 'Shipping Address', _style: 'width: 25%' },
  { key: 'billling_address', label: 'Billing Address', _style: 'width: 25%' },
  { key: 'billing_email', label: 'Billing Email', _style: 'width: 10%' },
  { key: 'currency_code', label: 'Currency', _style: 'width: 10%' },
  { key: 'edit', label: '', _style: 'width: 10%' }
]

export default {
  name: 'TableData',
  components: {
    RDataTable
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  created () {
    this.columns = columns
  },
  methods: {
    /**
     * Format an APIv2 address object as a human-readable
     * string.
     *
     * @param {(Object|null)} value
     * @returns {String}
     */
    formatAddress (value) {
      return formatAddress(value)
    },
    /**
     * Create the route link object for use with CLink.
     *
     * @param {Object} row
     * @returns {String}
     */
    getPropertyLink (row) {
      const adminUrl = process.env.VUE_APP_ADMIN_URL
      return `${adminUrl}companies/${row.company.id}/properties/${row.property.id}`
    }
  }
}
</script>

<style lang="scss" scoped>
.faded {
  color: $gray-600;
}
.icon-button {
  cursor: pointer;
  &:hover {
    color: $info;
  }
}
</style>
