var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',[_c('RDataTable',{attrs:{"select":"","items":_vm.data,"fields":_vm.columns,"table-props":{ loading: _vm.loading, sorter: false }},on:{"selection-change":function($event){return _vm.$emit('selection-change', $event)}},scopedSlots:_vm._u([{key:"customer_account",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"pb-1"},[_vm._v(" "+_vm._s(item.company ? item.company.human_name : 'Unknown')+" ")]),_c('div',{staticClass:"pb-1"},[_c('CLink',{attrs:{"href":_vm.getPropertyLink(item),"target":"_blank"}},[_vm._v(" "+_vm._s(item.property.name)+" ")])],1),(item.property.legal_name)?_c('div',{staticClass:"faded"},[_vm._v(" Legal: "+_vm._s(item.property.legal_name)+" ")]):_vm._e()])]}},{key:"property_address",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.property.property_address && item.property.property_address.attention_to)?_c('div',{staticClass:"faded"},[_vm._v(" Attn: "+_vm._s(item.property.property_address.attention_to)+" ")]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.formatAddress(item.property.property_address))+" ")])])]}},{key:"billling_address",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.property.billing_address && item.property.billing_address.attention_to)?_c('div',{staticClass:"faded"},[_vm._v(" Attn: "+_vm._s(item.property.billing_address.attention_to)+" ")]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.formatAddress(item.property.billing_address))+" ")])])]}},{key:"billing_email",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.property.billing_email)+" ")])]}},{key:"currency_code",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.company.currency_code)+" ")])]}},{key:"edit",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CIcon',{staticClass:"icon-button",attrs:{"name":"md-edit"},nativeOn:{"click":function($event){return _vm.$emit('edit-property', item)}}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }