<template>
  <CSidebar
    v-if="property"
    aside
    overlaid
    :show="show"
    color-scheme="light"
    size="xl"
    class="sidebar"
    @update:show="$emit('update:show', $event)"
  >
    <CSidebarClose @click.native="$emit('update:show', false)" />
    <div class="p-3">
      <h4>{{ property.name }}</h4>
      <h6>{{ company.human_name }}</h6>
      <AsideNotifications
        :error="notifications.error"
        :warning="notifications.warning"
        :success="notifications.success"
      />
      <div v-if="billingNotes">
        <hr>
        <div class="notes">
          {{ billingNotes }}
        </div>
      </div>
      <hr>
      <CForm>
        <CRow>
          <CCol>
            <CInput
              label="Customer Name"
              :value.sync="$v.formData.name.$model"
              :is-valid="validateField('name')"
              invalid-feedback="This field is required"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput label="Legal Name" :value.sync="formData.legal_name" />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput label="Currency" plaintext :value="company.currency_code" />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput
              label="Billing Email"
              :value.sync="$v.formData.billing_email.$model"
              :is-valid="validateField('billing_email')"
              invalid-feedback="This field must be an email"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <div class="form-group">
              <label>Master Invoice</label>
              <div>
                <CSwitch
                  shape="pill"
                  color="primary"
                  :checked.sync="formData.master_invoice"
                />
              </div>
            </div>
          </CCol>
        </CRow>
        <CRow v-if="!formData.master_invoice">
          <CCol>
            <CInput label="ZOHO Customer ID" :value.sync="formData.zoho_customer_id" />
          </CCol>
        </CRow>
        <CRow v-if="formData.master_invoice">
          <CCol>
            <span class="color-danger">Customers with master invoices will be removed from pending customers and pending invoices</span>
          </CCol>
        </CRow>
        <hr>
        <RInputAddress
          ref="billingAddress"
          label="Billing Address"
          v-bind.sync="formData.billing_address"
        />
        <hr>
        <RInputAddress
          ref="shippingAddress"
          label="Shipping Address"
          v-bind.sync="formData.property_address"
        />
      </CForm>

      <CRow class="controls">
        <CCol class="text-right">
          <CButton
            color="info"
            shape="pill"
            size="lg"
            class="mr-2"
            :disabled="formData.master_invoice"
            @click="handleSave({ create: true })"
          >
            Save and Create Account
          </CButton>
          <CButton
            color="info"
            shape="pill"
            size="lg"
            @click="handleSave({ create: false })"
          >
            Save
          </CButton>
        </CCol>
      </CRow>
    </div>
  </CSidebar>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'

import { RInputAddress } from '@/components/inputs'
import { InvoicesAPI, RooofAPI } from '@/services/api/resources'
import { createAddress, deepCopy, isEmptyAddress } from '@/utils'
import AsideNotifications from '@/views/staff/reporting/zoho/_components/AsideNotifications'

export default {
  name: 'PendingCustomersAside',
  components: {
    AsideNotifications,
    RInputAddress
  },
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required },
      billing_email: { email }
    }
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    property: {
      type: Object,
      default: null
    },
    company: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      billingNotes: '',
      formData: {
        name: '',
        legal_name: '',
        billing_email: '',
        zoho_customer_id: '',
        master_invoice: false,
        billing_address: createAddress(),
        property_address: createAddress()
      },
      notifications: {
        warning: '',
        error: '',
        success: false
      }
    }
  },
  watch: {
    property (value) {
      const formData = deepCopy(value)

      // Create empty address objects to hold form values
      if (formData.property_address === null) {
        formData.property_address = createAddress()
      }
      if (formData.billing_address === null) {
        formData.billing_address = createAddress()
      }
      this.formData = formData
    },
    company (value) {
      this.fetchBillingNotes()
    },
    show (value) {
      this.notifications = {
        error: '',
        warning: '',
        success: false
      }
    }
  },
  methods: {
    /**
     * Fetch the billing notes for the company.
     *
     * This field is only available as part of the
     * full company data, so it requires its own
     * request unfortunately.
     */
    async fetchBillingNotes () {
      this.billingNotes = ''
      const response = await RooofAPI.companies.retrieve({ id: this.company.id })
      if (response) {
        this.billingNotes = response.data.billing_notes
      }
    },
    /**
     * Send an update request to the api with new property
     * values.
     *
     * @param {Boolean} create - emit create event post-save
     */
    async handleSave ({ create = false }) {
      if (!this.isValid()) {
        return
      }
      this.notifications = {
        error: '',
        warning: '',
        success: false
      }
      // Remove empty address objects
      const billingAddress = isEmptyAddress(this.formData.billing_address)
        ? null
        : this.formData.billing_address
      const propertyAddress = isEmptyAddress(this.formData.property_address)
        ? null
        : this.formData.property_address
      const productSubscriptions = deepCopy(this.property.product_subscriptions)
      const featureSubscriptions = deepCopy(this.property.feature_subscriptions)
      // If master_invoice is set, clear the zoho_customer_id
      if (this.formData.master_invoice) {
        this.formData.zoho_customer_id = null
        const invoicingGroup = (await InvoicesAPI.invoicingGroups.retrieve({ id: this.property.invoicing_group })).data
        if (!invoicingGroup.zoho_recurring_invoice_id) {
          this.notifications.warning = 'This customer does not belong to an invoicing group with a Zoho Recurring ID.'
          return
        }
        // Update all subscriptions to have the invoicing group zoho_recurring_invoice_id
        productSubscriptions.forEach(function (subscription) {
          subscription.zoho_recurring_invoice = invoicingGroup.zoho_recurring_invoice_id
        })
        featureSubscriptions.forEach(function (subscription) {
          subscription.zoho_recurring_invoice = invoicingGroup.zoho_recurring_invoice_id
        })
      }

      const config = {
        id: this.property.id,
        data: {
          name: this.formData.name,
          legal_name: this.formData.legal_name,
          billing_email: this.formData.billing_email,
          master_invoice: this.formData.master_invoice,
          zoho_customer_id: this.formData.zoho_customer_id,
          billing_address: billingAddress,
          property_address: propertyAddress,
          product_subscriptions: productSubscriptions,
          feature_subscriptions: featureSubscriptions,
          unit_count: this.property.unit_count
        }
      }
      const response = await RooofAPI.properties.partialUpdate(config)
      if (response) {
        this.$emit('update:property', response.data)

        if (create) {
          this.$emit('create-account', response.data.id)
        }
      }

      this.handleClose()
    },
    /**
     * Validate the forms, triggering boostrap validaion.
     */
    isValid () {
      this.$v.$touch()
      const shippingAddressValid = this.$refs['shippingAddress'].validate()
      const billingAddressValid = this.$refs['billingAddress'].validate()
      return !this.$v.formData.$invalid && shippingAddressValid && billingAddressValid
    },
    /**
     * Validate a form field.
     *
     * @param {String} fieldName
     * @returns {Boolean}
     */
    validateField (fieldName) {
      const field = this.$v.formData[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !field.$invalid
    },
    /**
     * Close the aside and clear validation.
     */
    handleClose () {
      this.$v.$reset()
      this.$refs['shippingAddress'].reset()
      this.$refs['billingAddress'].reset()
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  overflow-y: auto;
  overflow-x: hidden;
}
.notes {
  white-space: pre-line;
  background-color: #E2F3FF;
  border: 2px solid #008FFB;
  border-radius: 5px;
  color: #1473E6;
  padding: 5px;
}
.color-danger {
  color: $danger;
}
.controls {
  margin-bottom: 7em;
}
// Hide the bootstrap validation icons
::v-deep .form-control {
  &.is-valid, &.is-invalid {
    background-image: none;
  }
}
</style>
